<script setup lang="ts">
import { KsIcon, KsInput } from '@aschehoug/kloss'
import { faMagnifyingGlass, faXmark } from '@fortawesome/pro-light-svg-icons'

const props = withDefaults(defineProps<{
  variant?: LayoutVariant
  shape?: SearchInputShape
  size?: InputSize
  name?: string
  resettable?: boolean
  placeholder?: string
  disabled?: boolean
  modelValue?: any
}>(), {
  variant: 'blurple',
  shape: 'rounded',
  size: 'medium',
  resettable: false,
  placeholder: 'Søk',
  disabled: false,
})

const emit = defineEmits<{
  (e: 'update:modelValue', value: string): void
}>()

const localValue = ref(props.modelValue || '')

watch(localValue, (newValue) => {
  emit('update:modelValue', newValue)
})

watch(() => props.modelValue, (newValue) => {
  localValue.value = newValue || ''
})

function reset() {
  if (props.resettable) {
    localValue.value = ''
  }
}
</script>

<template>
  <div class="relative flex w-full items-center outline outline-1" :class="[size, `search-${shape}`, `search-input-${variant}`]">
    <KsInput
      v-model="localValue"
      :name="name"
      :type="!resettable ? 'text' : 'search'"
      autocomplete="off"
      :placeholder="placeholder"
      :class="variant"
      :disabled="disabled"
      class="!bg-transparent !pr-12 truncate placeholder:!text-carbon-400 !shadow-none"
    />
    <button
      aria-label="search"
      class="absolute right-5 transition-all ease-in-out duration-500"
      :type="resettable && modelValue?.length > 0 ? 'button' : 'submit'"
      @click="reset"
    >
      <KsIcon :icon="resettable && localValue?.length ? faXmark : faMagnifyingGlass" scale="0.8" />
    </button>
  </div>
</template>

<style scoped lang='postcss'>
div {
  &.small {
    :deep(.ks-input) {
      @apply px-s py-xs;
    }
  }

  &.medium {
    :deep(.ks-input) {
      @apply py-xs pl-s pr-m h-[2.875rem];
    }
  }

  &.large {
    @apply p-s;

    :deep(.ks-input) {
      @apply py-xs pl-s pr-m;
    }
  }

  &.search-rounded {
    @apply rounded-full;
  }

  &.search-square {
    @apply rounded-lg;
  }

  &.search-input-carbon {
    @apply outline-carbon-500 hover:outline-2 hover:outline-carbon-300;

    :deep(.ks-input) {
      &.blurple {
        @apply dark:text-carbon-200 placeholder:text-blurple-500;
      }
    }
  }

  &.search-input-blurple {
    @apply outline-blurple-500 hover:outline-2 hover:outline-blurple-300;

    :deep(.ks-input) {
      &.blurple {
        @apply dark:text-carbon-200 placeholder:text-blurple-500;
      }
    }
  }

  &.search-input-polar {
    @apply outline-polar-500 hover:outline-2 hover:outline-polar-300;

    :deep(.ks-input) {
      &.polar {
        @apply dark:text-polar-500 placeholder:text-polar-500;
      }
    }
  }

  &.search-input-moss {
    @apply outline-moss-500 hover:outline-2 hover:outline-moss-300;

    :deep(.ks-input) {
      &.moss {
        @apply dark:text-moss-500 placeholder:text-moss-400;
      }
    }
  }
}
</style>
